/* src/components/FloatingButton.css */
.floating-button {
    position: fixed;
    bottom: 50px;
    right: 20px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .floating-button:hover {
    transform: scale(1.1);
  }
  
  
  /* Adjustments for mobile devices */
  @media screen and (max-width: 768px) {
    .floating-button {
      bottom: 80px;
      right: 15px;
      width: 50px;
      height: 50px;
    }
  }
  