input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
    display: none;
}

input[type=search] {
    padding: 15px 15px 15px 70px;
    background-image: url("https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png");
}

@media only screen and (max-width: 500px) {
    input[type=search] {
        padding: 10px;
        background: none;
        width: 100%;
    }
}